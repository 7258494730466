$(function () {
  $("body").on("click", "div[data-id]", function () {

    var _colorId = $(this).data("id");
    $(this).addClass("after:opacity-100").removeClass("after:opacity-0").siblings().removeClass("after:opacity-100").addClass("after:opacity-0");
    if (_colorId == 1) {
      $("#color-model").children('source').attr("srcset", "/static/img/models/1.png");
    } else if (_colorId == 2) {
      $("#color-model").children('source').attr("srcset", "/static/img/models/3.png");
    } else {
      $("#color-model").children('source').attr("srcset", "/static/img/models/2.png");
    }
  });
  $('div[data-id]').eq(0).click();
});