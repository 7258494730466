$(document).ready(function () {
    $('#more').on("click", function (e) {
       $('p').next().toggle()
      });
    $('#ajax-popup-privacy').magnificPopup({
    type:'ajax',
    mainClass: 'text-white backdrop-blur-md',
    ajax: {
        settings: null, // Ajax settings object that will extend default one - http://api.jquery.com/jQuery.ajax/#jQuery-ajax-settings
        // For example:
        // settings: {cache:false, async:false}

        cursor: 'mfp-ajax-cur', // CSS class that will be added to body during the loading (adds "progress" cursor)
        tError: '<a href="%url%">The content</a> could not be loaded.' //  Error message, can contain %curr% and %total% tags if gallery is enabled
        }
    });
});