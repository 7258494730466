import Form from "../helpers/Form";
import Popup from "../helpers/Popup";

$(document).ready(function () {
  var pop = new Popup($("#popup"));
  let success = new Popup($("#success"));
  let error = new Popup($("#error"));
  pop.i();
  success.i();
  error.i();

  $(document).on("click", "[js-popup]", function (e) {
    e.stopPropagation();
    e.preventDefault();
    pop.show($(this));
  });
  $(document).mouseup(function (e) {
    var container = $("#popup, #success, #error");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      pop.hide();
      success.hide();
      error.hide();
    }
  });
  $(document).on("submit", "form", function (e) {
    e.preventDefault();
    var form = $(this);
    var formObj = new Form(form);
    var formData = new FormData(this);
    var popup = form.parents("#popup");

    if (!formObj.isValid()) {
      for (let name in formObj.errors) {
        formObj.showError(name, formObj.errors[name]);
      }
      $(this).closest("#popup, .form").find("button").prop("disabled", true);
      return;
    }
    $(this).closest("#popup, .form").find("button").prop("disabled", false);

    form.find("[type=submit]").prop("disabled", true);

    if (popup.length) {
      pop.hide();
    }

    if (Comagic) {
      Comagic.addOfflineRequest({
        phone: form.find("input[type='tel']").val().replace(/\D/g, ""),
      });
    }

    form.find("input").val("");

    $.ajax({
      type: "POST",
      url: "ajax/send.php",
      data: formData,
      processData: false,
      contentType: false,
      success: (data) => {
        if (typeof ym !== undefined) {
          ym(91463698, "reachGoal", "send_form");
        }
        pop.hide();
        success.show();
        setTimeout(function () {
          form.find("button, [type=submit]").prop("disabled", false);
        }, 500);
        let sessionId = Comagic.getCredentials().session_id;
        let visitorId = Comagic.getCredentials().visitor_id;

        formData.append("session_id", sessionId);
        formData.append("visitor_id", visitorId);
        formData.append("visitor_session_id", 1);
      },
      error: (data) => {
        pop.hide();
        form.find("button, [type=submit]").prop("disabled", false);
        error.show();
      },
    });
  });
});
