(function () {
  var $carImg = $("#one img");
  var $carImgSecond = $("#second img");
  $("#colorSelector")
    .children()
    .on("click", function (e) {
      e.preventDefault();
      var imgPath = $(this).attr("data-img-path");
      $carImg.fadeOut(200, function () {
        $carImg.attr("src", imgPath).fadeIn(200);
      });

      $("#colorSelector")
        .children()
        .removeClass(
          "before:absolute before:content-[''] before:w-2 before:h-4 before:border-b-2 before:border-r-2 before:translate-y-1/2 before:-translate-x-1/2 before:left-1/2 before:rotate-45"
        );
      $(this).addClass(
        "before:absolute before:content-[''] before:w-2 before:h-4 before:border-b-2 before:border-r-2  before:translate-y-1/2 before:-translate-x-1/2 before:left-1/2 before:rotate-45"
      );
    });
    $("#colorSelectorSecond")
    .children()
    .on("click", function (e) {
      e.preventDefault();
      var imgPath = $(this).attr("data-img-path");
      $carImgSecond.fadeOut(200, function () {
        $carImgSecond.attr("src", imgPath).fadeIn(200);
      });

      $("#colorSelectorSecond")
        .children()
        .removeClass(
          "before:absolute before:content-[''] before:w-2 before:h-4 before:border-b-2 before:border-r-2 before:translate-y-1/2 before:-translate-x-1/2 before:left-1/2 before:rotate-45"
        );
      $(this).addClass(
        "before:absolute before:content-[''] before:w-2 before:h-4 before:border-b-2 before:border-r-2  before:translate-y-1/2 before:-translate-x-1/2 before:left-1/2 before:rotate-45"
      );
    });
})();
