const Map = {
    i: (obj) => {
        Map.obj = obj;
        if (Map.obj.hasClass('inited'))
            return;

        Map.map = new ymaps.Map("" + Map.obj.attr('id') + "", {
            center: Map.getCenter(),
            zoom: Map.getZoom(),
            controls: []
        }, {
            suppressMapOpenBlock: true,

        });

        if ($(window).width() > 800) {
            Map.map.controls.add('zoomControl', {
                size: "small",
                position: {
                    right: 20,
                    top: 230
                },
            });
        } else {
            Map.map.controls.add('zoomControl', {
                size: "small",
                position: {
                    right: 20,
                    top: 70
                },
            });
        }

        Map.makeBalloonLayout();
        Map.makeContentLayout();
        Map.putData();
        Map.obj.addClass('inited');
    },

    getCenter: () => {
        if ($(window).width() > 800) {
            return [55.725934, 37.374102];
        } else {
            return [55.725934, 37.374102];
        }
    },

    getZoom: () => {
        return 12;
    },

    putData: () => {
        Map.putPoint();
    },

    putPoint: () => {
        var content = '<div class="relative p-5 bg-[#F9F9F9] ">' +
                '<div class="map-close absolute top-2 right-2"><img class=" w-3 brightness-50" src="/static/img/icons/cancel.png"></div>' +
                '<img class=" mb-2 max-w-[170px] " src="/static/img/logos/logo-blue.png">' +
                '<p class="text-black text-md mb-2 ">Официальный дилер Кунцево</p>' +
                '<p class="text-black text-xs mb-1">г. Москва, ул. Горбунова, 14</p>' +
                '<p class="text-black text-xs mb-1">Пн — Вс, 08:30 — 21:00</p>' +
                '<a class=" mb-4 block"  href="tel:+74959334033">+7 (495) 933-40-33</a>' +
                '<div class="inline-flex items-center justify-center whitespace-nowrap border border-skyblue bg-transparent px-4 py-2 text-base text-black shadow-sm hover:bg-skyblue hover:text-white w-full cursor-pointer" data-title="Заказать звонок" js-popup="">' +
                    '<span class="">Заказать звонок</span>' +
                '</div>' +
            '</div>';

        var dealer = window.myPlacemark = new ymaps.Placemark([55.725934, 37.374102], {
            balloonContent: content
        }, {
            balloonShadow: false,
            balloonLayout: Map.layout,
            balloonContentLayout: Map.contentLayout,
            balloonPanelMaxMapArea: 0,
            iconLayout: 'default#imageWithContent',
            iconImageHref: 'static/img/map-baloon.svg',
            iconImageSize: [65, 80],
            iconImageOffset: [-40, -40],
            balloonOffset: [-250, 10]
        });

        Map.map.geoObjects.add(dealer);
    },

    makeBalloonLayout: () => {
        Map.layout = ymaps.templateLayoutFactory.createClass(
            '<div class="map-dealer">' +
            '<div class="arrow"></div>' +
            '$[[options.contentLayout observeSize minWidth=290 maxWidth=290 maxHeight=400]]' +
            '</div>', {
                build: function () {
                    this.constructor.superclass.build.call(this);

                    this._$element = $('.map-dealer', this.getParentElement());

                    this.applyElementOffset();

                    this._$element.find('.map-close')
                        .on('click', $.proxy(this.onCloseClick, this));
                },
                clear: function () {
                    this._$element.find('.map-close')
                        .off('click');

                    this.constructor.superclass.clear.call(this);
                },
                onSublayoutSizeChange: function () {
                    Map.layout.superclass.onSublayoutSizeChange.apply(this, arguments);

                    if (!this._isElement(this._$element)) {
                        return;
                    }

                    this.events.fire('shapechange');
                },
                applyElementOffset: function () {
                    this._$element.css({
                        left: -(this._$element[0].offsetWidth / 2),
                        top: -(this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight)
                    });
                },
                onCloseClick: function (e) {
                    e.preventDefault();
                    this.events.fire('userclose');
                },
                getShape: function () {
                    if (!this._isElement(this._$element)) {
                        return Map.layout.superclass.getShape.call(this);
                    }

                    var position = this._$element.position();

                    return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                        [position.left, position.top], [
                            position.left + this._$element[0].offsetWidth,
                            position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
                        ]
                    ]));
                },
                _isElement: function (element) {
                    return element && element[0] && element.find('.arrow')[0];
                }
            }
        )
    },

    makeContentLayout: () => {
        Map.contentLayout = ymaps.templateLayoutFactory.createClass(
            '<div>$[properties.balloonContent]</div>'
        );
    }
};

export default Map;