$( function() {
    var icons = {
      header: "ui-icon-caret-1-s shrink-0",
      activeHeader: "ui-icon-caret-1-n shrink-0"
    };
    $( "#accordion" ).accordion({
      active: 1,
      heightStyle:"content",
       classes: {
        "ui-accordion-header": "!flex flex-row-reverse justify-between"
      },
      icons: icons
    });
    $( "#toggle" ).button().on( "click", function() {
      if ( $( "#accordion" ).accordion( "option", "icons" ) ) {
        $( "#accordion" ).accordion( "option", "icons", null );
      } else {
        $( "#accordion" ).accordion( "option", "icons", icons );
      }
    });
  } );