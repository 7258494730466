import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiperMainbanner = new Swiper(".swiper.swiper-main_banner", {
  autoplay: {
    delay: 5000,
  },
  slidesPerView: 1,
  // pagination: true,
  loop: true,
  autoHeight: false,
  // navigation: {
  //   nextEl: ".swiper-button-next",
  //   prevEl: ".swiper-button-prev",
  // },
  modules: [Navigation],
});

const swiperGallery = new Swiper(".swiper.swiper-gallery-et5", {
  slidesPerView: 5,
  // pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 5,
    },
  },
  autoHeight: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  modules: [Navigation],
});
 new Swiper(".swiper.swiper-gallery-ht-i", {
  slidesPerView: 5,
  // pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 5,
    },
  },
  autoHeight: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  modules: [Navigation],
});
new Swiper(".swiper.swiper-model-view", {
  autoplay: {
    delay: 15000,
  },
  slidesPerView: 1,
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  modules: [ Pagination],
});

(function () {
  "use strict";
  const breakpoint = window.matchMedia("(min-width:768px)");

  var swiperView, swiperReview;
  const breakpointChecker = function () {
    if (breakpoint.matches === true) {
      if (swiperView !== undefined) swiperView.destroy(true, true);
      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  };

  const enableSwiper = function () {
    swiperView = new Swiper(".swiper.swiper-view", {
      // Navigation arrows
      autoHeight: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation],
    });

    swiperReview = new Swiper(".swiper.swiper-review", {
      // Navigation arrows
      autoHeight: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation],
    });
  };

  // keep an eye on viewport size changes
  breakpoint.addListener(breakpointChecker);

  // kickstart
  breakpointChecker();
})(); /* IIFE end */
