$(window).on("scroll", function () {
  var scroll = $(window).scrollTop();
  if ($(window).width() < 768) {
    if (scroll >= 770) {
      $("header").children().addClass("bg-black/75");
    } else {
      $("header").children().removeClass("bg-black/75");
    }
  }
  if (scroll >= 20) {
      $("header").children().addClass("bg-black/75");
    } else {
      $("header").children().removeClass("bg-black/75");
    }
}).trigger("scroll");
